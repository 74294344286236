import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  alpha,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Iconify from "../../../components/iconify";
import { RouterLink } from "../../../routers/components";
import { paths } from "../../../routers/paths";
import { usePathname } from "../../../routers/hooks";
import { useBoolean } from "../../../hooks/use-boolean";
import ExchangeDialog from "../../components/ExchangeDialog";
import { useGetMeQuery } from "../../../core/user/user.query";

const AccountNavbar = () => {
  const pathname = usePathname();

  const { data } = useGetMeQuery();

  const [user, setUser] = useState(null);

  const exchange = useBoolean();

  const [formExchange, setFormExchange] = useState(null);

  useEffect(() => {
    if (!data) return;
    setUser(data);
  }, [data]);

  useEffect(() => {
    if (!formExchange) return;
    // console.log(formExchange);
  }, [formExchange]);

  const handleOpenExchange = () => {
    exchange.onTrue();
  };

  return (
    <>
      <Stack spacing={2} px={2}>
        <ListItem
          sx={{
            p: 0,
          }}
        >
          <ListItemAvatar
            sx={{
              width: 48,
              height: 48,
              mr: 1,
            }}
          >
            {user && (
              <Avatar
                src={user.avatar}
                alt={user.username}
                sx={{
                  width: 1,
                  height: 1,
                }}
                variant="circular"
              >
                {user.username.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={user?.username}
            primaryTypographyProps={{
              variant: "body1",
            }}
            secondary={`ID: ${user?.id}`}
            secondaryTypographyProps={{
              variant: "subtitle2",
              color: "text.primary",
            }}
          />
        </ListItem>

        <List
          sx={{
            p: 0,
            m: 0,
            bgcolor: "background.paper",
            borderRadius: 0.5,
          }}
        >
          <ListItemButton
            LinkComponent={RouterLink}
            href={paths.customer.profile}
            sx={{
              borderRadius: 0.5,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              color: "text.secondary",
              pl: 1.5,
              py: 1.5,
              ...(pathname === paths.customer.profile && {
                bgcolor: "primary.main2",
                color: "primary.contrastText",
              }),
              "&:hover": {
                ...(pathname === paths.customer.profile
                  ? {
                      bgcolor: "primary.main2",
                      color: "primary.contrastText",
                    }
                  : {
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.1),
                      color: "primary.main",
                    }),
              },
            }}
          >
            <ListItemIcon>
              <Iconify icon="bxs:user" width={24} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
              }}
            >
              Thông tin tài khoản
            </ListItemText>
          </ListItemButton>
          {/* <Divider /> */}
          {/* <ListItemButton
            LinkComponent={RouterLink}
            href={paths.customer.notification}
            sx={{
              color: "text.secondary",
              ...(pathname === paths.customer.notification && {
                bgcolor: "primary.main2",
                color: "primary.contrastText",
              }),
              pl: 1.5,
              py: 1.5,
              "&:hover": {
                ...(pathname === paths.customer.notification && {
                  bgcolor: "primary.main2",
                  color: "primary.contrastText",
                }),
              },
            }}
          >
            <ListItemIcon>
              <Iconify icon="bxs:bell" width={24} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
              }}
            >
              Thông báo
            </ListItemText>
          </ListItemButton> */}
          <Divider />
          {/* dialog */}
          <ListItemButton
            sx={{
              pl: 1.5,
              py: 1.5,
              color: "text.secondary",
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
                color: "primary.main",
              },
            }}
            onClick={handleOpenExchange}
          >
            <ListItemIcon>
              <Iconify
                icon="bxs:dollar-circle"
                width={24}
                sx={{
                  color: "primary.main",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
              }}
            >
              Nạp tiền (Tự động)
            </ListItemText>
          </ListItemButton>
          <Divider />
          <ListItemButton
            LinkComponent={RouterLink}
            href={paths.customer.transaction}
            sx={{
              borderRadius: 0.5,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              color: "text.secondary",
              ...(pathname === paths.customer.transaction && {
                bgcolor: "primary.main2",
                color: "primary.contrastText",
              }),
              pl: 1.5,
              py: 1.5,
              "&:hover": {
                ...(pathname === paths.customer.transaction
                  ? {
                      bgcolor: "primary.main2",
                      color: "primary.contrastText",
                    }
                  : {
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.1),
                      color: "primary.main",
                    }),
              },
            }}
          >
            <ListItemIcon>
              <Iconify icon="bxs:credit-card" width={24} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
              }}
            >
              Lịch sử nạp tiền
            </ListItemText>
          </ListItemButton>
        </List>

        <List
          sx={{
            p: 0,
            m: 0,
            bgcolor: "background.paper",
            borderRadius: 0.5,
          }}
        >
          <ListItemButton
            LinkComponent={RouterLink}
            href={paths.customer.nicks}
            sx={{
              borderRadius: 0.5,
              color: "text.secondary",
              ...(pathname === paths.customer.nicks && {
                bgcolor: "primary.main2",
                color: "primary.contrastText",
              }),
              pl: 1.5,
              py: 1.5,
              "&:hover": {
                ...(pathname === paths.customer.nicks
                  ? {
                      bgcolor: "primary.main2",
                      color: "primary.contrastText",
                    }
                  : {
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.1),
                      color: "primary.main",
                    }),
              },
            }}
          >
            <ListItemIcon>
              <Iconify
                icon="bxs:basket"
                width={24}
                sx={{
                  color: "primary.main",
                  ...(pathname === paths.customer.nicks && {
                    color: "primary.contrastText",
                  }),
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "body2",
              }}
            >
              Tài khoản đã mua
            </ListItemText>
          </ListItemButton>
        </List>
      </Stack>

      <ExchangeDialog
        open={exchange.value}
        onClose={exchange.onFalse}
        setForm={setFormExchange}
      />
    </>
  );
};

export default AccountNavbar;
